exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ommamaco-js": () => import("./../../../src/pages/ommamaco.js" /* webpackChunkName: "component---src-pages-ommamaco-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-spirit-baby-messages-js": () => import("./../../../src/pages/spirit-baby-messages.js" /* webpackChunkName: "component---src-pages-spirit-baby-messages-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-yogateaching-js": () => import("./../../../src/pages/yogateaching.js" /* webpackChunkName: "component---src-pages-yogateaching-js" */)
}

